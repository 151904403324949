const STATUS_ICONS = {
    OPERATIONAL: {
        icon: 'check',
        color: '#2fcc66',
        title: 'Operational'
    },
    DEGRADED_PERFORMANCE: {
        icon: 'squared-minus',
        color: '#f1c40f',
        title: 'Degraded performance'
    },
    PARTIAL_OUTAGE: {
        icon: 'warning',
        color: '#e67e22',
        title: 'Partial outage'
    },
    MAJOR_OUTAGE: {
        icon: 'cancel',
        color: '#e74c3c',
        title: 'Major outage'
    },
    NOT_EXECUTED: {
        icon: 'wrench',
        color: '#444444',
        title: 'Major outage'
    }
}

export { STATUS_ICONS };
