import React, { useEffect } from 'react';

import AddSubscriberDialog from '../components/subscribers/AddSubscriberDialog';
import ConfirmationDialog from '../components/subscribers/ConfirmationDialog';
import RemoveUserDialog from '../components/subscribers/RemoveUserDialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Icon from '../components/Icon/index.js';
import Box from '@material-ui/core/Box';

import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import { getCookieByName } from '../funcs.js';
import { Container } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { getSubscribersList, putSubscribersList , deleteSubscriber } from '../actions';

const Subscribers = () => {
    const [removeUserDialogError, setRemoveUserDialogError] = React.useState(false);
    const [getSubscribersError, setGetSubscribersError] = React.useState(false);
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
    const [failureModalOpen, setFailureModalOpen] = React.useState(false);
    const [addUserDialog, setAddUserDialog] = React.useState(false);
    const [removeUserDialog, setRemoveUserDialog] = React.useState(false);
    const [succesModalOpen, setSuccesModalOpen] = React.useState(false);
    const [userToDeleteId,setUserToDeleteId] = React.useState('');
    const [openDialog, setOpenDialog] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [rows, setRows] = React.useState([]);

    const theme = useTheme();
    const dialogId = "confirmationDialog";
    const removeColumnIndex = 3;
    const TOKEN = window.name !== '' ? getCookieByName(window.name+'_token') : getCookieByName('token');
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const columns = [
        { field: 'name', headerName: 'Name', width: windowWidth > 1001 ? '405px' : windowWidth / 2 - 150 + 'px' },
        { field: 'email', headerName: 'Email', width: windowWidth > 1001 ? '405px' : windowWidth / 2 - 150 + 'px' },
        //this renderMethod to add a Remove column is because the renderCell method was not working 
        { field: ' ', headerName: ' ', width: '100px', valueGetter: () => 'Remove' }
    ]

    window.addEventListener('resize', () => {
        setWindowWidth(window.innerWidth)
    })

    const useStyles = makeStyles(() => ({
        root: {
            width: '90%',
            maxWidth: '1000px',
            marginBottom: '30px',
            marginTop: '30px',
            //this selector is used dua to materialUi lack of flexibility to add Ids to columns/cells
            '& .MuiDataGrid-cell[data-value="Remove"]' : {
                color: '#3f51b5',
                textAlign: 'center',
                fontSize: '12px',
                cursor: 'pointer'
            }
        },
        subcribersTile: {
            color: '#3e3e3e',
            textAlign: 'right'
        },
        updateSubscribersButton: {
            marginTop: '30px',
        },
        newSubscriberButton : {
            marginBottom: '20px'
        },
        loader: {
            margin: "auto",
            position: "absolute",
            left: "50%",
            top: "50%",
        },
        errorMessage: {
            color: '#3e3e3e',
            fontWeight: "bold",
            textAlign: "center",
            margin: "auto",
            marginTop: "calc(50% - 32px)"
        }
    }));

    const classes = useStyles();

    const handleOpen = () => {
        setOpenDialog(true);
    }

    const handleClose = () => {
        setTimeout(() => { 
            setSuccesModalOpen(false);
            setFailureModalOpen(false); 
        }, 500);
        setOpenDialog(false);
    }

    const subscribersList = async () => {
        const response = await getSubscribersList(TOKEN);

        if (response.fulfilled) {
            setRows(response.collection);
            setLoading(false);
        }else{
            setGetSubscribersError(false);
            setLoading(false);
        }
    }

    const refreshSubscribersList = () => {
        subscribersList(TOKEN);
    }

    const modifySubscribersList = async () => {
        const response = await putSubscribersList(TOKEN,rows);

        if (response.fulfilled && !response.error) {
            setSuccesModalOpen(true);
            setOpenDialog(true);
         }else{
            setFailureModalOpen(true);
            setOpenDialog(true);
        }
    }
    
    const handleNewSelection = (newSelection) => {
        let tempRows = rows;
        tempRows.map(user => user.subscribed = false)
        newSelection.selectionModel.forEach(id => {
            tempRows.find(user => {
                if (user.id === id) {
                    user.subscribed = true;
                }
            })
        });
        setRows(tempRows);
    }

    const openAddSubscriberDialog = () => {
        setAddUserDialog(true);
    }

    const closeAddUserDialog = () => {
        setAddUserDialog(false);
    }
    
    const removeUserConfirmation = (params, e) => {
        if(params.colIndex === removeColumnIndex){
            e.stopPropagation();
            setRemoveUserDialog(true);
            setUserToDeleteId(params.row.id);
        }
    }
    
    const confirmedUserRemotion = async () => {
        const response = await deleteSubscriber(TOKEN, userToDeleteId)
        if(response.fulfilled){
            let tempRows = rows.filter(user => user.id !== userToDeleteId);
            setRows(tempRows);
            setRemoveUserDialog(false);
        }else{
            setRemoveUserDialog(true);
            setRemoveUserDialogError(true)
        }
    }

    const selectionModel = rows.filter((user) => user.subscribed).map((user) => user.id);

    const renderDialogContent = () => {
        return <ConfirmationDialog dialogId={dialogId} successDialogOpen={succesModalOpen} failureDialogOpen={failureModalOpen} close={handleClose} confirmAction={modifySubscribersList} />
    }
    const renderContent = () => {
        if (getSubscribersError) {
            return (
            <h2 className={classes.errorMessage}>we are sorry! Please refresh the page or try again later
            <Box style={{marginTop: '20px'}}>
                <Link
                    to={{ pathname: "/" }}
                    style={{ textDecoration: 'none' }}
                    >
                <Button
                    variant="outlined"
                    color="primary"
                    className={classes.subscribersButton}
                    startIcon={<Icon icon='arrow' color='#3f51b5' title='go back' size="14px" />}>
                    Back
                </Button>
                </Link>
            </Box>
            </h2>);
        } else {
            return (
                <div style={{ 'height': '500px' }}>
                    {
                        removeUserDialog 
                        && <RemoveUserDialog 
                                open={removeUserDialog} 
                                user={userToDeleteId} 
                                removeUserError={removeUserDialogError}
                                rows={rows} 
                                removeUser={removeUserConfirmation} 
                                confirmedRemotion={confirmedUserRemotion} 
                                onClose={()=>setRemoveUserDialog(false)}/>
                    }
                    {
                        addUserDialog && <AddSubscriberDialog 
                                            open={addUserDialog} 
                                            rows={rows}
                                            onSuccess={refreshSubscribersList}
                                            onClose={closeAddUserDialog}/>
                    }
                    <div>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Link to={{ pathname: "/" }}
                                style={{ textDecoration: 'none' }}
                            >
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.subscribersButton}
                                startIcon={<Icon icon='arrow' color='#3f51b5' title='go back' size="14px" />}>
                                Back
                            </Button>
                            </Link>
                            <h2 className={classes.subcribersTile}>Subscribers List</h2>
                        </Box>
                        <Box display="flex" justifyContent="flex-end">
                            <Button className={classes.newSubscriberButton} color="primary" onClick={openAddSubscriberDialog} variant="contained">
                                <span id="add-subscriber-button">
                                    Add New Subscriber
                                </span>
                            </Button>
                        </Box>
                    </div>
                    <DataGrid
                        checkboxSelection
                        className={classes.datagrid}
                        columns={columns}
                        autoHeight={false}
                        onCellClick={removeUserConfirmation}
                        onSelectionModelChange={handleNewSelection}
                        pageSize={5}
                        rows={rows}
                        selectionModel={selectionModel}
                    />
                    <Box display="flex" justifyContent="flex-end">
                        <Button id="update-subscribers-button" className={classes.updateSubscribersButton} color="primary" onClick={handleOpen} variant="contained">Update Subscribers</Button>
                    </Box>
                    <Dialog fullScreen={fullScreen} open={openDialog} aria-labelledby={dialogId}>
                        {renderDialogContent()}
                    </Dialog>
                </div>
            )
        }
    }

    useEffect(() => {
        if (!TOKEN) {
            if (document.location.hostname !== "localhost") {
                window.location.href = "undefined/#/logout";
            }
        }
        return () => {
            window.removeEventListener('resize', ()=>{});
        }
    }, []);

    useEffect(()=>{
        subscribersList();
    }, [])

    return (
        <Container className={classes.root} id="subscribers-container">
            {loading ? <CircularProgress className={classes.loader} /> : renderContent()}
        </Container>
    )
}

export default Subscribers;