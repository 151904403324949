import React from 'react';

import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import { EMAIL_REG_EX } from '../../constants/RegEx';
import { useTheme } from '@material-ui/core/styles';
import { getCookieByName } from '../../funcs.js';
import { addSubscriber } from '../../actions.js';
import {v4 as uuidv4} from 'uuid';

const AddSubscriberDialog = (props) => {
    const TOKEN = window.name !== '' ? getCookieByName(window.name+'_token') : getCookieByName('token');
    const properties = props;
    const theme = useTheme(); 

    const [isEmailInvalid, setIsEmailInvalid]  = React.useState(true);
    const [addUserError, setAddUserError] = React.useState(false);
    const [emailHelperText, setEmailHelperText] = React.useState('');
    const [userEmail, setUserEmail] = React.useState('');
    const [userName, setUserName] = React.useState('');

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const dialogId = 'add-user-dialog';

    const handleUserNameChange = (e) => {
        setUserName(e.target.value);
    };

    const handleUserEmailChange = (e) => {
        setUserEmail(e.target.value);

        if(e.target.value.length > 0){
            if(EMAIL_REG_EX.test(e.target.value)){
                setEmailHelperText(''); 
                setIsEmailInvalid(false);
            }else{
                setEmailHelperText('Plase provide a valid email'); 
                setIsEmailInvalid(true);
            }
        }

        properties.rows.map(user => {
            if(user.email === e.target.value){
                setEmailHelperText('This email is already registered'); 
                setIsEmailInvalid(true);
            }
        })
    };

    const addNewSubscriber = async () =>{
        const response = await addSubscriber(TOKEN, userName, userEmail, uuidv4());
        if(response.fulfilled){
            properties.onSuccess(TOKEN);
            properties.onClose();
        }else{
            setAddUserError(true);
        }
    }
    if(!addUserError){
        return(
            <Dialog open={properties.open} onClose={properties.onClose} fullScreen={fullScreen} aria-labelledby={dialogId} PaperComponent={Paper}>
                <DialogTitle><span id={dialogId+"-title"}>{"Add New Subscriber"}</span></DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="subscriber-name-input"
                        label="Name"
                        type="text"
                        onChange={handleUserNameChange}
                        fullWidth
                    />
    
                    <TextField
                        autoFocus
                        margin="dense"
                        id="subscriber-email-input"
                        label="Email"
                        type="email"
                        helperText= {emailHelperText}
                        onChange={handleUserEmailChange}
                        fullWidth
                    />
               
                </DialogContent>
                <DialogActions>
                    <Button onClick={properties.onClose} color="primary" autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={addNewSubscriber} color="primary" autoFocus disabled={isEmailInvalid}>
                        <span id="add-subscriber-confirm-button">
                            Add Subscriber
                        </span>
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }else{
        return (
            <Dialog open={properties.open} onClose={properties.onClose} fullScreen={fullScreen} aria-labelledby={dialogId} PaperComponent={Paper}>
            <DialogTitle><span id={dialogId+"-title"}>{"We are sorry!"}</span></DialogTitle>
            <DialogContent>
                <DialogContentText>
                    We encountered an adding this user please try again later
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={properties.onClose} color="primary" autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
        )
    }
}

export default AddSubscriberDialog;