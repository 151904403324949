import React, { useEffect } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import StatusLegend from '../components/home/StatusLegend.js';
import StatusTable from '../components/home/StatusTable.js';
import Container from '@material-ui/core/Container';

import { makeStyles } from '@material-ui/core/styles';
import * as actions from '../actions';
import { getCookieByName } from '../funcs';

const TOKEN = window.name !== '' ? getCookieByName(window.name+'_token') : getCookieByName('token');
const urlParams = new URLSearchParams(window.frames.frames.location.search);
const params = Object.fromEntries(urlParams.entries());

const Home = () => {
    const useStyles = makeStyles(() => ({
        root: {
            width: '90%',
            maxWidth: '1200px',
        },
        name: {
            verticalAlign: 'middle', 
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            float: 'left',
            fontWeight: 500,
            maxWidth: '75%',
        },
        status: {
            display: 'inline-block',
            fontSize: '0.875rem',
            float: 'right'
        },
        timeLineGraphic: {
            margin: 0,
            padding: 0,
            height: '34px',
            width: '100%',
            overflow: 'hidden'
        },
        loader: {
            margin: "auto",
            position: "absolute",
            left: "50%",
            top: "50%",
        }
    }));

    const [collection, setCollection] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const classes = useStyles();

    let isMounted = true;
    let statusWebSocket;
  
    const getStatus = async () => {
        const opts = {groups: params.groups};
        const response = await actions.getLatestStatus(TOKEN, opts);
        if (response.fulfilled) {
            setCollection(response.collection);
            setLoading(false);
            setStatusWebSocketConnection();
        }else{
            setLoading(false);
        }
    }
   
    const setStatusWebSocketConnection = () => {
        const webSocketHost = `wss://curbside-status-app.cox2m.com/status${params.group ? `?group=${params.groups}`: '' }`;
        statusWebSocket = new WebSocket(webSocketHost)

        statusWebSocket.onmessage = event => {
            const data = JSON.parse(event.data);
            if (isMounted) {
                setCollection(collection => {
                    const eventIndex = collection.findIndex((status => status.itemId === data.itemId));
                    if (eventIndex !== -1) {
                        collection[eventIndex] = data;
                        return [...collection];
                    }
                    return [...collection, data];
                });
            }
        }
    }

    useEffect(async () => {
        await getStatus();
        return () => { isMounted = false; if(statusWebSocket) statusWebSocket.close();}
    }, []);

    
    return (
        <Container maxWidth="sm" className={classes.root}>
            {loading ?
                <CircularProgress className={classes.loader} /> :
                <div>
                    <StatusTable collection={collection} />
                    <StatusLegend />
                </div>
            }
        </Container>
    )
}
export default Home;