import React from 'react';

import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const ConfirmationDialog = (props) => {
    if (!props.successDialogOpen && !props.failureDialogOpen) {
        return (
            <div>
                <DialogTitle id={props.dialogId}>{"Confirm your action"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <span id="modify-subscribers-default-text">  
                            Do you want to modify Subscribers list?
                        </span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={props.close} color="primary">
                        <span id="modify-subscribers-cancel-button">
                            Close
                        </span>
                    </Button>
                    <Button onClick={props.confirmAction} color="primary" autoFocus>
                        <span id="modify-subscribers-confirm-button">
                            Confirm
                        </span>
                    </Button>
                </DialogActions>
            </div>
        )
    } else if (props.successDialogOpen) {
        return (
            <div>
                <DialogTitle ><span id={props.dialogId+'-success'}>{"Success!"}</span></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <span id="modify-subscribers-success-text">
                            Subscribers list was modified Correctly
                        </span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button id="modify-subscribers-cancel-button" autoFocus onClick={props.close} color="primary">
                        <span id="modify-subscribers-cancel-button">
                            Close
                        </span>
                    </Button>
                </DialogActions>
            </div>
        )
    } else if (props.failureDialogOpen) {
        return (
            <div>
                <DialogTitle id={props.dialogId}><span id={props.dialogId+'-failure'}>{"Failure!"}</span></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <span id="modify-subscribers-error-text">
                            We are sorry! we couldnt modify the subscribers list, please try again later
                        </span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={props.close} color="primary">
                        <span id="modify-subscribers-cancel-button">
                            Close
                        </span>
                    </Button>
                </DialogActions>
            </div>
        )
    }
}

export default ConfirmationDialog;