import * as Api from 'status_api';

const {
    ApiClient,
    ItemApi,
    ItemDetailApi,
    SubscriberApi
} = Api;

/**
 * Initialize the API Client
 */
const initClient = () => {
    const apiClient = new ApiClient();
    apiClient.basePath = 'https://curbside-status-app.cox2m.com';
    apiClient.defaultHeaders = {
        'Access-Control-Allow-Origin': '*'
    };

    return apiClient;
};
const apiClient = initClient();

/**
 * Initialize the API client
 */
const initApi = (Api) => {
    return new Api(apiClient);
};

const subscribersApi = initApi(SubscriberApi);
const itemDetailApi = initApi(ItemDetailApi);
const itemApi = initApi(ItemApi);

/**
 * Set token to use in security scheme
 * @param token
 */
const setToken = (token) => {
    apiClient.authentications["API_TOKEN"].apiKey = token;
};

/**
 * Get last details from status
 * @param {string} token
 */
export const getLatestStatus = async (token, opts = {}) => {
    try {
        setToken(token);
        const response = await itemDetailApi.getDetailsLatest(opts);
        return { fulfilled: true, collection: response }
    } catch (err) {
        return { fulfilled: false, error: err }
    }
}

/**
 * Get history range from a given id and range
 * @param {string} token
 * @param {string} from
 * @param {string} to
 * @param {string} id
 */
export const getHistoryRangeFromGivenId = async (token, from, to, id) => {
    try {
        setToken(token);
        const response = await itemDetailApi.getDetailsRange(from, to, id);
        return { fulfilled: true, collection: response }
    } catch (err) {
        return { fulfilled: false, error: err }
    }
}

/**
 * Get the subscribers list
 * @param {string} token
 */
export const getSubscribersList = async (token) => {
    try {
        setToken(token);
        const response = await subscribersApi.getSubscribers();
        return { fulfilled: true, collection: response }
    } catch (error) {
        return { fulfilled: false, error: error }
    }
}

/**
 * Put the subscribers list to modify the selected users
 * @param {string} token
 * @param {array<SubscriberDto>} subscribers
 */
export const putSubscribersList = async (token, subscribers) => {
    try {
        setToken(token);
        await subscribersApi.putSubscribers({ subscriberDto: subscribers });
        return { fulfilled: true }
    } catch (error) {
        return { fulfilled: false, error: error }
    }
}

/**
 * add a subscriber from the subscribers list to modify the selected users
 * @param {string} token
 * @param {string} name
 * @param {string} email
 */

export const addSubscriber = async (token, name, email, id) => {
    try {
        setToken(token);
        await itemApi.postSubscribers({
            subscriberDto:
            {
                id,
                email,
                name,
                subscribed: false
            }
        });
        return { fulfilled: true }
    } catch (error) {
        return { fulfilled: false, error: error }
    }
}

/**
 * delete a subscriber from subscribers list
 * @param {string} token
 * @param {string} subscriberID
 */

export const deleteSubscriber = async (token, subscriberId) => {
    try {
        setToken(token);
        await itemApi.deleteSubscribersWithId(subscriberId);
        return { fulfilled: true }
    } catch (error) {
        return { fulfilled: false, error: error }
    }
}
