import React from 'react';

import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import HistoryBar from './HistoryBar';
import Icon from '../Icon/index.js';
import PropTypes from 'prop-types';

import { STATUS_ICONS } from '../../constants/StatusIcons';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    accordionContainer: {
        margin: '1% !important',
        padding: '1.4rem 1.25rem 1.3rem',
        boxShadow: 'none',
        borderStyle: 'solid',
        borderColor: '#E0E0E0',
        borderWidth: '2px',
        borderRadius: '2px !important',
    },
    statusSummary: {
        flexBasis: '33.33%',
        flexShrink: 0,
        verticalAlign: 'middle',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        display: 'inline-block',
        fontWeight: 600,
        maxWidth: '75%',
        textAlign: 'start',
        width: '100%'
    },
    statusDetail: {
        flexBasis: '33.33%',
        flexShrink: 0,
        verticalAlign: 'middle',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        display: 'inline-block',
        maxWidth: '75%',
        textAlign: 'start',
        width: '100%',
        marginLeft: '35px',
    },
    summaryContent: {
        display: 'grid',
        gridTemplateColumns: '35px auto 20px'
    },
    detailContent: {
        display: 'initial',
        padding: '0px'
    },
    detailStatusRow: {
        display: 'grid',
        gridTemplateColumns: 'auto 20px',
        padding: '10px 16px'
    },
    descriptionText: {
        color: '#525252',
        fontSize: '0.9rem',
        marginTop: "10px"
    },
    galleryTextContainer: {
        display: "flex",
        color: '#525252',
        fontSize: '0.9rem',
        marginTop: "10px",
        justifyContent: "flex-end",
        flexDirection: "row",
        alignItems: "center",
    },
    imagesContainer: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        columnGap: '20px',
        width: '100%',
        paddingLeft: '20px',
    },
    screenshotSummary: {
        '& .MuiAccordionSummary-content': {
            margin: "0"
        },
        '& p': {
            marginLeft: '20px'
        },
        height: '30px',
        minHeight: '30px'
    },
    screenshotText: {
        '& svg': {
            marginLeft: '10px'
        },
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'space-around'
    },
    image: {
        maxWidth: '100px',
        height: 'auto'
    }
}));

const CardAccordion = ({status, expanded, changeFunction}) => {
    const classes = useStyles();

    return (
        <Accordion id={status.id + '-testing-accordion'} className={classes.accordionContainer} expanded={expanded === `panel-${status.id}`} onChange={changeFunction(`panel-${status.id}`)} >
            <AccordionSummary classes={{ content: classes.summaryContent }}>

                <Icon id={status.id + '-expand-icon'} icon={expanded === `panel-${status.id}` ? 'minus-square-o' : 'plus-square-o'} color="#AAAAAA" size="24px" />
                <Typography className={classes.statusSummary}>{status.name}</Typography>
                { expanded !== `panel-${status.id}` && <Icon {...STATUS_ICONS[status.status]} size="20px" />}
                {(expanded === `panel-${status.id}` && status.description !== null) &&
                    <Box width={1} style={{ gridColumn: '1/-1' }} >
                        <Typography className={classes.descriptionText}>{status.description}</Typography>
                    </Box>

                }
            </AccordionSummary>
            <AccordionDetails id={status.id + '-accordion-details'} className={classes.detailContent}>
                {status.details && status.details.map((detail, index) => {
                    const title = detail.name || detail.deviceId || detail.serialNumber || detail.alias || detail.message;
                    return (
                        <div key={`${index}_${new Date().getTime()}`} className={classes.detailStatusRow}>
                            <span className={classes.statusDetail} title={title}>
                                {title}
                            </span>
                            {detail.status && <Icon {...STATUS_ICONS[detail.status]} size="20px" />}
                            {(detail.screenshots && detail.screenshots.length > 0) &&
                                <Accordion style={{ marginTop: '10px', gridColumn: '1/-1', boxShadow: 'unset' }}>
                                    <AccordionSummary className={classes.screenshotSummary} expandIcon={
                                        <span id={status.id + '-expand-' + index}><Icon icon="chevron" title="go to screenshots" color="#444444" size="15px" /></span>
                                    }>
                                        <Typography className={classes.screenshotText}>
                                            screenshots
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className={classes.imagesContainer}>
                                            {detail.screenshots.map((item, index) => (
                                                <div key={index} style={{display:'flex', justifyContent: 'center'}}>
                                                    <img 
                                                        id={detail.serialNumber + '-screenshot-' + index}
                                                        className={classes.image}
                                                        src={item}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            }
                        </div>
                    )
                }
                )}
                <HistoryBar status={status} />
            </AccordionDetails>
        </Accordion>
    );
}

CardAccordion.propTypes = {
    status: PropTypes.object.isRequired,
    expanded: PropTypes.any.isRequired,
    changeFunction: PropTypes.func.isRequired
}

export default CardAccordion;
