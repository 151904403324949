import React from 'react';

import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import { useTheme } from '@material-ui/core/styles';

const RemoveUserDialog = (props) => {
    const properties = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const dialogId = 'remove-user-dialog';

    if(!properties.removeUserError){
        return(
            <Dialog open={properties.open} onClose={properties.onClose} fullScreen={fullScreen} aria-labelledby={dialogId} PaperComponent={Paper}>
                <DialogTitle ><span id={dialogId+'-title'}>{"Confirm your action"}</span></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <span id={dialogId+'-default-message'}>
                            do you want to remove this user from the subscribers list?
                        </span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={properties.onClose} color="primary" autoFocus>
                        <span id={dialogId+'-cancel-button'}>
                            Cancel
                        </span>
                    </Button>
                    <Button onClick={properties.confirmedRemotion} color="primary" autoFocus>
                        <span id={dialogId+'-confirm-button'}>
                            Confirm
                        </span>
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }else{
        return(
            <Dialog open={properties.open} onClose={properties.onClose} fullScreen={fullScreen} aria-labelledby={dialogId} PaperComponent={Paper}>
                <DialogTitle ><span id={dialogId+'-title'}>{"We are sorry!"}</span></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <span id={dialogId+'-error-message'}>
                            We encountered an error removing the user please try again later
                        </span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={properties.onClose} color="primary" autoFocus>
                        <span id={dialogId+'-cancel-button'}>
                            Cancel
                        </span>
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
    
export default RemoveUserDialog;
