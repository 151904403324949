/**
 * Verifies if a string is empty or not
 * @param {string} str
 * @returns {boolean}
 */
export const isEmpty = (str) => !str || str === '';

/**
 * Capitalize a given string
 * @param {string} str
 * @returns {string}
 */
export const capitalize = (str) => str.charAt(0).toUpperCase() + str.substring(1);

/**
 * Converts a string to Title Case. e.g.: fooBar => Foo Bar
 * @param {string} str
 * @returns {string}
 */
export const titleCase = str => str.replace(
  /\w\S*/g,
  txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
);

/**
 * Time since the given date
 * @param {Date} date
 * @returns {string}
 */
export const timeSince = date => {
  const seconds = Math.floor((new Date() - date) / 1000);
  let interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return interval + " years";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + " months";
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + " days";
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + " hours";
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + " minutes";
  }

  return Math.floor(seconds) + " seconds";
};

/**
 * Receives a string that contains an unformatted object (it's not a JSON string)
 * @param {string} str
 * @returns {object}
 */
export const getUnformattedObject = (str) => {
  const stringToObject = new Function('return ' + str + ';');
  return stringToObject();
}


/**
 * Returns a cookie value from a given name
 * @param {string} cname
 * @returns {object}
 */
export const getCookieByName = (cname) => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i].trim();
    const index = c.indexOf(name);
    if (index >= 0) {
      return c.substring(index + name.length, c.length);
    }
  }
  return "";
}


/**
 * Returns a CSV string from a given array of objects
 * @param {Array} arrayOfObjects
 * @returns {string}
 */
export function objectsArrayToCsv(arrayOfObjects) {
  function getColumnKeys(obj, prefix = '') {
    if (typeof obj === 'undefined' || obj === null) return [];
    return [
      ...Object.keys(obj).map(key => `${prefix}${key}`).filter(key => typeof obj[key] !== 'object'),
      ...Object.entries(obj).reduce((acc, [key, value]) => {
        if (typeof value === 'object') return [...acc, ...getColumnKeys(value, `${prefix}${key}.`)];
        return acc;
      }, []),
    ];
  }

  function flatObject(obj, prefix = '') {
    if (typeof obj === 'undefined' || obj === null) return {};
    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (typeof value === 'object') return { ...acc, ...flatObject(value, `${prefix}${key}.`) };
      return { ...acc, [`${prefix}${key}`]: value };
    }, {});
  }

  function escapeCsvValue(cell) {
    if (cell.replace(/ /g, '').match(/[\s,"]/)) {
      return '"' + cell.replace(/"/g, '""') + '"';
    }
    return cell;
  }

  // collect all available keys
  const keys = new Set(arrayOfObjects.reduce((acc, item) => [...acc, ...getColumnKeys(item)], []));
  // for each object create all keys
  const values = arrayOfObjects.map(item => {
    const flattenObject = flatObject(item);
    const val = Array.from(keys).map((key) => (key in flattenObject ? escapeCsvValue(`${flattenObject[key]}`) : ''));
    return val.join(';');
  });
  return `${Array.from(keys).join(';')}\n${values.join('\n')}`;
}

/**
 * Returns a Promise to me solved in a given time
 * @param {number} ms
 * @returns {Promise}
 */
export const waitFor = ms => new Promise(r => setTimeout(r, ms));
