import React, {useState} from 'react';

import Container from '@material-ui/core/Container';
import CardAccordion from './CardAccordion';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '90%',
        maxWidth: '1000px',
        marginBottom: '30px',
        marginTop: '30px',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    subscribersBox: {
        margin: '30px auto',
        maxWidth: '1000px',
        paddingRight: '5px'
    },
    subscribersButton: {
        textDecoration: 'none'
    },
}));

const GridContainer = (props) => {
    const classes = useStyles();

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <Container maxWidth="sm" className={classes.root}>
            <Grid id="status-collection" container spacing={3}>
                {props.collection.map((status, index) => (
                    <Grid item md={6} sm={12} xs={12} key={"test" + index}>
                        <CardAccordion status={status} index={index} expanded={expanded} changeFunction={handleChange} />
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}

GridContainer.propTypes = {
    collection: PropTypes.array
}

export default GridContainer;
