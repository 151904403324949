import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import Icon from '../Icon';

import { Popover, Typography, Box } from '@material-ui/core';
import { getHistoryRangeFromGivenId } from '../../actions';
import { STATUS_ICONS } from '../../constants/StatusIcons';
import { makeStyles } from '@material-ui/core/styles';
import { getCookieByName } from '../../funcs';

const TOKEN =
    window.name !== ''
        ? getCookieByName(window.name + '_token')
        : getCookieByName('token');
const uptimePercentageForDays = 30;
const useStyles = makeStyles(() => ({
    name: {
        verticalAlign: 'middle',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        display: 'inline-block',
        float: 'left',
        fontWeight: 500,
        maxWidth: '75%',
    },
    status: {
        display: 'inline-block',
        fontSize: '0.875rem',
        float: 'right',
    },
    timeLineGraphic: {
        margin: 0,
        padding: 0,
        height: '34px',
        width: '100%',
        overflow: 'hidden',
    },
    popover: {
        pointerEvents: 'none',
    },
    popoverBox: {
        boxShadow: '0 3px 6px rgb(0 0 0 / 15%)',
        border: '1px solid rgba(0,0,0,0.2)',
        borderRadius: '3px',
        width: '325px',
        padding: '15px',
    },
    date: {
        marginLeft: '15px',
        marginBottom: 0,
        fontSize: '14px',
        fontWeight: 500,
    },
    outages: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontWeight: 500,
        margin: '10px 0',
        border: '1px solid rgba(255,255,255,0)',
        padding: '9px 14px 8px 14px',
        borderRadius: '2px',
        height: '40px',
        backgroundColor: 'rgba(236,238,241,0.31)',
    },
    popoverItemText: {
        fontSize: '14px',
    }
}));

const HistoryBar = (props) => {
    const { status } = props;
    const itemId = status.id;

    const [collection, setCollection] = React.useState([]);

    const getHistory = () => {
        const toDate = new Date().toISOString().split('T')[0];
        let fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - 30);
        fromDate = fromDate.toISOString().split('T')[0];
        getHistoryRangeFromGivenId(TOKEN, fromDate, toDate, status.itemId).then(
            (response) => {
                if (response.fulfilled) {
                    response.collection.forEach((collection) => {
                        collection.details.map((detail) =>
                            detail.downtime >= hoursToMs(4)
                                ? (detail.status = 'MAJOR_OUTAGE')
                                : detail.status
                        );
                    });
                    setCollection(response.collection);
                }
            }
        );
    };

    const [popoverElement, setPopoverElement] = React.useState(null);
    const classes = useStyles();

    const hoursToMs = (hours) => {
        return hours * 60 * 60 * 1000;
    };

    const handlePopoverOpen = (event, value) => {
        setPopoverElement({
            target: event.target,
            status: value,
        });
    };

    const handlePopoverClose = () => {
        setPopoverElement(null);
    };

    const shouldDisplayPopover = Boolean(popoverElement);

    const getUptimePercentage = (collection) => {
        return Math.floor(collection
            .slice(
                (collection.length < uptimePercentageForDays ? collection.length : uptimePercentageForDays)*(-1)
            )
            .reduce((accumulator, current) => accumulator = accumulator + current.uptimePercentage, 0) / (collection.length < uptimePercentageForDays ? collection.length : uptimePercentageForDays))
    }

    useEffect(() => {
        getHistory();
    }, []);

    useEffect(() => {
    }, [collection]);

    return collection.length > 0 ? (
        <Box
            border={1}
            borderColor='#eceef1'
            padding='1.1rem'
            id={itemId + '-history-bar'}
        >
            <Box verticalalign='baseline'>
                <span className={classes.name}>{status.name}</span>
                <span className={classes.status} id={itemId + '-history-status'}>
          {STATUS_ICONS[status.status].title}
        </span>
                <Box paddingTop='30px' marginBottom='-2px'>
                    <p>
                        Uptime Percentage of the last {collection.length < uptimePercentageForDays ? collection.length : uptimePercentageForDays} days:{' '}

                        {getUptimePercentage(collection)}%

                    </p>
                    <svg
                        className={classes.timeLineGraphic}
                        preserveAspectRatio='none'
                        height={34}
                        viewBox='0 0 298 34'
                    >
                        {collection.map((status, index) => {
                                return (<rect
                                    id={itemId + '-history-' + index}
                                    key={index}
                                    height={34}
                                    width={9}
                                    fill={
                                        status.details.filter(
                                            (detail) => detail.status !== 'OPERATIONAL'
                                        ).length === 0
                                            ? '#56BE8E'
                                            : status.details.filter(
                                                (detail) => detail.downtime >= hoursToMs(4)
                                            ).length > 0
                                                ? '#E74C3C'
                                                : '#C0BD35'
                                    }
                                    x={index * 10}
                                    y='0'
                                    onMouseEnter={(e) => handlePopoverOpen(e, status)}
                                    onMouseLeave={handlePopoverClose}
                                />)
                            }
                        )}
                    </svg>
                </Box>
            </Box>
            <Popover
                id='mouse-over-popover'
                className={classes.popover}
                open={shouldDisplayPopover}
                anchorEl={shouldDisplayPopover ? popoverElement.target : null}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {popoverElement && (
                    <Box className={classes.popoverBox}>
                        <Typography className={classes.date}>
                            {popoverElement.status.timestamp}
                        </Typography>
                        <Box>
                            {popoverElement.status.details.filter(item => item.status !== 'OPERATIONAL').length > 0 ? (
                                popoverElement.status.details.map((item, index) => {
                                    const DOWNTIME_HOURS = item.downtime / 3600000; 
                                    const DOWNTIME_MINUTES = Math.round(
                                        (DOWNTIME_HOURS - Math.floor(DOWNTIME_HOURS)) * 60
                                    );
                                    if(item.status !== 'OPERATIONAL') {
                                        return (
                                            <Box key={index} className={classes.outages}>
                                                <Icon {...STATUS_ICONS[item.status]} size='24px' />
                                                <Typography className={classes.popoverItemText}>{item.description}</Typography>
                                                <Typography className={classes.popoverItemText}>
                                                    {Math.floor(DOWNTIME_HOURS)} hrs {DOWNTIME_MINUTES} mins
                                                </Typography>
                                            </Box>
                                        );
                                    }
                                })
                            ) : (
                                <Box className={classes.outages}>
                                    <Typography>No downtime recorded on this day.</Typography>
                                </Box>
                            )}
                        </Box>
                    </Box>
                )}
            </Popover>
        </Box>
    ) : (
        ''
    );
};

HistoryBar.propTypes = {
    status: PropTypes.object.isRequired,
};

export default HistoryBar;