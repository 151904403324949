import React from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Icon from '../Icon';

import { makeStyles } from '@material-ui/core/styles';
import { STATUS_ICONS } from '../../constants/StatusIcons';

const useStyles = makeStyles(() => ({
    legendContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px 0px'
    },
    legendItem: {
        display: 'flex',
        justifyContent: 'center'
    },
    icon: {
        paddingRight: '5px',
        marginBottom: '30px'
    }, 
    legendContainerGrid: {
        justifyContent: 'space-around'
    }
}));

const StatusLegend = () => {
    const classes = useStyles();
    
    return (
        <div className={classes.legendContainer}>
            <Grid container spacing={0} display="flex" className={classes.legendContainerGrid} id="status-legend">
                <Grid item sm={3} xs={6} className={classes.legendItem} >
                    <Icon {...STATUS_ICONS.OPERATIONAL} size="20px" className={classes.icon} />
                    <Typography>Operational</Typography>
                </Grid>
                <Grid item sm={3} xs={6} className={classes.legendItem} >
                    <Icon {...STATUS_ICONS.DEGRADED_PERFORMANCE} size="20px" className={classes.icon} />
                    <Typography>Degraded Performance</Typography>
                </Grid>
                <Grid item sm={3} xs={6} className={classes.legendItem} >
                    <Icon {...STATUS_ICONS.PARTIAL_OUTAGE} size="20px" className={classes.icon} />
                    <Typography>Partial Outage</Typography>
                </Grid>
                <Grid item sm={3} xs={6} className={classes.legendItem} >
                    <Icon {...STATUS_ICONS.MAJOR_OUTAGE} size="20px" className={classes.icon} />
                    <Typography>Major Outage</Typography>
                </Grid>
                <Grid item sm={3} xs={6} className={classes.legendItem} >
                    <Icon {...STATUS_ICONS.NOT_EXECUTED} size="20px" className={classes.icon} />
                    <Typography>Not Executed</Typography>
                </Grid>
            </Grid>
        </div>
    );
}

export default StatusLegend;
