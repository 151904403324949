import React, {useEffect} from 'react';

import Subscribers from "./forms/Subscribers.js";
import Home from "./forms/Home.js";

import {Route, Switch, useLocation} from 'react-router-dom';

const App = () => {
    const usePageViews = () => {
        const location = useLocation();
        useEffect(() => {
            window.gtag("config", "G-WKV8GGKJRT", {
                page_title: location.pathname,
                page_path: location.pathname,
            });
        }, [location]);
    };

    usePageViews();

    return (

        <div id="status-container" className="App">
            <Switch>
                <Route exact path="/">
                    <Home/>
                </Route>
                <Route exact path="/subscribers">
                    <Subscribers/>
                </Route>
            </Switch>
        </div>
        
    );
}

export default App;
