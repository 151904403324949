import React from "react";
import PropTypes from "prop-types";
import IcomoonReact from "icomoon-react";
import iconSet from "./selection.json";

function Icon(props) {
    const { id="", color = "black", size = "16px", icon = "eye", className = "" } = props;
    if( id !== ""){
        return (
            <IcomoonReact id={id} 
                className={className}
                iconSet={iconSet}
                color={color}
                size={size}
                icon={icon}
            />
        );
    }else{
        return (
            <IcomoonReact
                className={className}
                iconSet={iconSet}
                color={color}
                size={size}
                icon={icon}
            />
        );
    }
}

Icon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.string,
    icon: PropTypes.string,
    className: PropTypes.string
}

export default Icon;